<template>
  <div class="emission_product">
    <div class="emission_product__header">
      <page-title-action :title="$t('cfp_emissions_product.title_register_emissions_product')"
        :isFixedButton="isFixedButton"
        @handleActionByName="handleActionByName"
        :isHasData="isHasDataTable"
        :workflowData="workflowData"
        >
      </page-title-action>
    </div>
    <div class="emission-product__body">
      <div class="emission-product__body__info">
        <TitleBar :title="processDetailInfo?.process_name">
          <custom-dropdown :options="dropdownOptions" v-model="processDetailInfo.selectedOption" @getDataPulldown="getDataPulldown" :processDetailInfo="processDetailInfo" :isReadOnly="isReadOnlyDataTable"></custom-dropdown>
        </TitleBar>

        <div class="emission-product__body__info__unit-section">
          <div class="emission-product__body__info__unit-section__input-block">
            <label>{{ $t('cfp_emissions_product.total_discharge_emissions_product') }}</label>
            <div class="text-field">{{ formatNumber(totalEmissions) }} <span class="text-field-unit">kg〜CO2e</span></div>
          </div>
          <div class="emission-product__body__info__unit-section__input-block emission-quantity">
            <label>{{ $t("cfp_emissions_product.number_of_applicable_products_emissions_product") }}
              <Tooltip :tooltipText="getTooltipQuantityProduct" class="tooltip" />
            </label>
            <InputField :isNumberType="true" :isPositiveNumber="true" :isLargerThan0="true" class="input-custom color-textColor input-service-link"
              :contentTooltip="contentToolTipProductName"
              :isHasToolTip="isChangedProductNumber" 
              :inputValue="processDetailInfo.productNumber" @update:inputValue="handleUpdateProductNumber" :isReadOnly="isReadOnlyDataTable" />

          </div>
          <div class="emission-product__body__info__unit-section__input-block">
            <label>{{ $t('cfp_emissions_product.emission_per_product_emissions_product') }}</label>
            <div class="text-field">{{ formatNumber(emissionsPerProduct) }} <span class="text-field-unit">kg〜CO2e/{{ processDetailInfo.productUnit }}</span></div>
          </div>
        </div>
        <div class="emission-product__body__info__note-section">
          <label>{{ $t('cfp_emissions_product.note_emissions') }}</label>
          <InputField class="input-custom color-textColor input-service-link" :inputValue="processDetailInfo.note"
            @blur="handleCheckNote"
            :errorMessages="productEmissionsErrorMessages.noteErrorMessages"
            @focus="productEmissionsErrorMessages.noteErrorMessages = []"
            @update:inputValue="handleUpdateNoteField" :isReadOnly="isReadOnlyDataTable" />
        </div>
      </div>
      <div class="emission-product__body__table register-emission-product">
        <data-table 
          :init-grid="initGrid"
          :data-source="emissionsProductGrid"
          :grid-columns="getHeaderTable"
          :allowRightClick="!isReadOnlyDataTable" 
          :isShowToolbar="false" 
          :rowFocus="1"
          :showFocus="false" 
          :isReadOnly="isReadOnlyDataTable"
          :allowDelete="true"
          @updateAutoFill="handleUpdateAutoFill"
          @actionRightClick="actionRightClick"
          @checkTableHasData="handleContextPasted"
        />
      </div>
    </div>
    <div v-if="isMobile" ref="scrollElement">
      <page-title-action
        :title="$t('cfp_emissions_product.title_register_product')"
        :workflowData="workflowData"
        :isFixedButton="isFixedButton"
        @handleActionByName="handleActionByName"
        :isHasData="isHasDataTable"
        hiddenIfDoNotHaveAnyButton
        ></page-title-action>
    </div>
    <service-link-source-popup
      :dialog="serviceLinkDialog"
      :selectedRow="selectedRow"
      :codeProductValue="codeProductValue"
      :listServicesLinked="listServicesLinked"
      :onlyViewedServicesLinked="onlyViewedServicesLinked"
      @submit="addServiceLinkSource"
      @close="serviceLinkDialog = false"
    />
    <EvidenceStoragePopup 
      :dialog.sync="openDialogEvidenceStorage" 
      :typeForm="typeFormEvidenceStorage" 
      :descriptionScope="descriptionScope" 
      :evidences="evidences" 
      @onSubmitSuccess="handleAddAttachFile" 
      @onDelete="handleDeleteAttachFile"
      :isProcessProduct="true"
    />
    <notification-popup
      :dialog="dialog.notification"
      :message="message.notification"
      :isErrorImg="true"
      @submit="dialog.notification = false"
    />
    <notification-popup 
      :dialog="dialog.notificationSaved" 
      :message="message.savedChange"
      @submit="goToEmissionsProcessWorkflow" />
    <question-popup
      :confirmText="confirmText"
      :dialog="questionPopupDialog"
      :message="questionPopupMessage"
      :closeText="closeText"
      :isSaveButtonDelete="isUpdateDataApproved"
      @close="closeQuestionPopup"
      @submit="submitQuestionPopup"
    />
    <external-source-popup
      :dialog="externalSourceDialog"
      :selectedRow="selectedRow"
      :detailExternalSource="detailExternalSource"
      @submit="addExternalSource"
      @close="externalSourceDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageTitleAction from '@/components/products/common/page-title-action.vue';
import TitleBar from '@/components/products/table/TitleBar';
import CustomDropdown from '@/components/commonApp/CustomDropdown';
import InputField from '@/components/products/input/InputField.vue';
import DataTable from '@/components/category/data-table';
import QuestionPopup from '@/components/dialogs/question-popup';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { CollectionView } from "@mescius/wijmo";
import { actionProcessEmissionProduct, getDbProcessEmission } from '@/api/product/emissionsProduct';
import { BLANK_ID } from "@/constants/registerData";
import * as wjcCore from "@mescius/wijmo";
import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from '@mescius/wijmo.input';
import { prepareMasterOrCustomizeDB, getDbCustomizeOrDbMasterById, makeNewId } from '@/concerns/utils/master-or-customize-db';
import { DB_TYPE } from '@/constants/dbType';
import { ACTION_CODE } from '@/constants/rightClickAction.js';
import { getColumnIndexByBinding } from '@/concerns/utils/filter-data'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, validateNumberField } from '@/concerns/newRegisterData/wijmo.helper'
import throttle from 'lodash/throttle'
import { addThoundSandCommaWithTwoDigit, addThoundSandComma } from '@/utils/convertNumber';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
import { getAllDbSource, dbIdeaBySourceName} from '@/api/ideaDbSource';
import { getAllDbServiceLink, getServicesLinked, dbServiceLinkObj, dbServiceLinkBySourceName } from '@/api/serviceLink';
import { getWidthOfSmartLcaBtn, getWidthOfAttachedButton } from '@/utils/multiLanguage';
import ServiceLinkSourcePopup from '@/components/dialogs/service-link-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { validateMaximumCharactorOnlyFacility, validateBlankRowData } from "@/utils/validate";
import { KEYS_CODE } from '@/constants/keyboard';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { ROUTES } from '@/router/constants';
import { dbIdeaObj } from '@/api/ideaDbSource';
import ExternalSourcePopup from '@/components/dialogs/external-source-popup';
import EvidenceStoragePopup from '@/views/emissions/EvidenceStorage/index.vue';
import { STATUS_FIELD } from '@/constants/status';
import { ROLE } from '@/constants/role';
import Tooltip from '@/components/commonApp/Tooltip';
import moment from 'moment';

export default {
  components: { PageTitleAction, TitleBar, InputField, DataTable, CustomDropdown, NotificationPopup, ServiceLinkSourcePopup, ExternalSourcePopup, EvidenceStoragePopup, Tooltip, QuestionPopup },
  data() {
    return {
      data: [],
      isLoading: false,
      inputValue: null,
      isHasDataSearched: false,
      isNextStepSearch: false,
      isSearching: false,
      serviceLinkData: {},
      flexGrid: null,
      emissionsProductGrid: null,
      emissionsProductColumns: [],
      isLoadingSubmit: false,
      emissionsProductData: [],
      listOriginalData: [],
      dropdownOptions: [
        { value: false, label: '算出対象' },
        { value: true, label: 'カットオフ対象' },
      ],
      dbMasterOriginal: [],
      totalEmissions: null,
      emissionsPerProduct: null,
      isFixedButton: true,
      isMobile: window.innerWidth < 1024,
      lostFocusId: null,
      codeProductValue: null,
      serviceLinkDialog: false,
      onlyViewedServicesLinked: false,
      dbExternalAdded: {
        [DB_TYPE.IDEA]: [],
        [DB_TYPE.SERVICE_LINK]: [],
      },
      listServicesLinked: [],
      openDialogEvidenceStorage: false,
      typeFormEvidenceStorage: 'view',
      onlyViewEvidence: false,
      evidences: {},
      itemsChangeEvidence: {},
      externalSourceDialog: false,
      detailExternalSource: null,
      selectedRow: null,
      isHasDataTable: false,
      processDetailInfo: {
        id: null,
        note: '',
        selectedOption: false,
        productNumber: null,
        productUnit: '',
        noteOriginal: '',
        quantityOriginal: null,
        methodOriginal: false
      },
      dialog: {
        notification: null,
        notificationSaved: null
      },
      message: {
        notification: '',
        savedChange: ''
      },
      productEmissionsErrorMessages: {
        noteErrorMessages: []
      },
      questionPopupDialog: false,
      questionPopupMessage: '',
      confirmText: '',
      closeText: '',
      isUpdateDataApproved: false
    };
  },
  async mounted() {
    // this.actionUpdateIsLoading(true)
    if (window.innerWidth < 1024) {
      this.isFixedButton = true;
    }
    window.addEventListener('resize', this.handleResize);
    await this.getListDb(),
    await Promise.all([
      this.getEmissionProductByProcess(),
      getAllDbServiceLink(),
      getAllDbSource(),
      this.handleGetServicesLinked()
    ]);
    this.updateBreadCrumb(this.breadCrumb());
    document.addEventListener('scroll', this.updateActionPosition);
  },
  computed: {
    ...mapState('userData', ['existDbSource', 'currentUser']),
    getHeaderTable() {
      if (!this.flexGrid) return;
      const header = this.initGridColumn();
      if (!this.existDbSource) {
        this.removeHeaderItem(header, 'view_detail')
      }
      if (this.listServicesLinked.length === 0) {
        this.removeHeaderItem(header, 'service_detail')
      }
      return header;
    },
    statusApproved() {
      const status = this.processDetailInfo?.status_product_emissions;
      const isWorkflowApproved = [
        STATUS_FIELD.STATUS_REPRENSENT_APPROVED,
        STATUS_FIELD.STATUS_APPROVED
      ].includes(status);
      return isWorkflowApproved;
    },
    descriptionScope() {
      return {
        onlyView: this.onlyViewEvidence,
        subTitle: this.geSubTitleEvidence
      }
    },
    isChangedProductNumber() {
      return this.processDetailInfo?.changed_columns?.quantity ? true : false
    },
    contentToolTipProductName() {
      return this.getTootipContent(this.processDetailInfo?.changed_columns?.quantity)
    },
    getTooltipQuantityProduct() {
      return this.$t('cfp_emissions_product.tooltip_product_involved_register');
    },
    isReadOnlyDataTable() {
      const status = this.processDetailInfo?.status_product_emissions;
      const isOwner = this.processDetailInfo?.is_owner;
      const isUserNotAdmin = this.currentUser?.user?.role_id !== ROLE.ADMIN;
      const isStatusCanceled = status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED;
      const isStatusSubmitted = status === STATUS_FIELD.STATUS_SUBMIT;
      const isStatusDraft = status === STATUS_FIELD.STATUS_DRAFT;
      const isStatusReject = [STATUS_FIELD.STATUS_RETURN, STATUS_FIELD.STATUS_REPRENSENT_RETURN].includes(status);

      return (isUserNotAdmin && this.statusApproved) || isStatusCanceled || isStatusSubmitted || (isStatusDraft && !isOwner) || (isStatusReject && !isOwner);
    },
    geSubTitleEvidence() {
      const productName = this.$t('cfp_emissions_product.hyperlink_product_name', { productName: this.processDetailInfo?.product_name });
      const boundaryName = this.processDetailInfo?.boundary_name || '';
      const processName = this.processDetailInfo?.process_name || '';
      return `${productName} > ${boundaryName} > ${processName}`.trim();
    },
    dataStoreData() {
      return getDbCustomizeOrDbMasterById(this.dbMasterOriginal);
    },
    workflowData() {
      return {
        is_tmp: this.processDetailInfo.status_product_emissions === null ? 1 : 0,
        isReadOnlyDataTable: this.isReadOnlyDataTable,
        status_product_emissions: this.processDetailInfo.status_product_emissions
      }
    }
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoading", "actionUpdateIsLoadingTable"]),
    breadCrumb() {
      const breadCrumb = [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      ]
      const typeDetail = this.$route.query?.type;
      if(typeDetail === 'detail') {
        breadCrumb.push({
          text: this.$t('b_list_product.title_list_products'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        });
      } else if(typeDetail === 'submit') {
        breadCrumb.push({
          text: this.$t('b_list_submited.label_application_status'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
        });
      } else if(typeDetail === 'approval') {
        breadCrumb.push({
          text: this.$t('left_menu.button_approval'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
        });
      }
      breadCrumb.push(
        {
          text: this.$t('cfp_emissions_product.hyperlink_product_name', {productName: this.processDetailInfo?.product_name}), //TODO: update text
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/product-detail/${this.processDetailInfo?.product_id}?type=${typeDetail}`,
        },
        {
          text: this.processDetailInfo?.boundary_name,
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/emissions-detail/${this.processDetailInfo?.product_emission_id}?type=${typeDetail}`,
        },
        {
          text: this.processDetailInfo?.process_name,
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      );
      return breadCrumb;
    },
    getTootipContent(item) {
      if (!item?.updated_at || !item?.updated_by) return ''
      let updatedAt = moment.utc(item.updatedAt).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
      if (this.isCheckLang === 'vi') {
        return `${this.$t("register_data.description_modified_by")} ${this.$t("register_data.description_administrator")} ${item.updated_by}\n` + updatedAt
      } else {
        return item.updated_by + ` ${this.$t("register_data.description_modified_by")} \n` + updatedAt
      }
    },
    goToEmissionsProcessWorkflow() {
      this.dialog.notificationSaved = false;
      this.$router.push({name: "EmissionsDetailProduct", params: {emissionId: this.processDetailInfo?.product_emission_id}, query: this.$route.query}).catch(() => {});
    },
    initGrid(grid) {
      this.flexGrid = grid;

      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.getHeaderTable;
      }

      document.addEventListener('keydown', (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });
      this.actionUpdateIsLoadingTable(false);
      grid.hostElement.addEventListener(
        'keydown',
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(0, currentSelection.col);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, 2);
              grid.selection = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1 && !this.isReadOnlyDataTable) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                grid.itemsSource.commitNew();
              });
            }
          }
          if (e.keyCode === KEYS_CODE.KEY_V) {
            if (this.isReadOnlyDataTable) return;
            this.actionUpdateIsLoadingTable(true)
            const { row, row2 } = grid.selection
            const isSingleRowPasted = row2 === row
            if (isSingleRowPasted) {
              setTimeout(() => { this.actionUpdateIsLoadingTable(false) }, 300)
            }
          }
        },
        false,
      );
      grid.formatItem.addHandler((s, e) => { 
        const colBinding = e.panel.columns[e.col].binding;
        if(e.panel == s.cells && colBinding === 'attach_file') {
          if (e.cell.querySelector('button') && e.cell.querySelector('img')) {
            const img = e.cell.querySelector('img');
            let fileAttached = 'file_attached.svg';
            if (img) {
              if (this.statusApproved) {
                const rowData = s.rows[e.row].dataItem;
                if (rowData['changed_columns']?.evidence) {
                  fileAttached = 'file_attached_approved.svg';
                  if (e.cell.querySelector('div')) {
                    e.cell.querySelector('.file-attached-container').classList.add('approved');
                  }
                }
              } else {
                fileAttached = 'file_attached.svg';
              }
              img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              e.cell.querySelector('button').addEventListener('mouseenter', function () {
                img.setAttribute('src', require('@/assets/icons/file_attached_active.svg'));
              })
              e.cell.querySelector('button').addEventListener('mouseleave', function () {
                img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              })
            }
          }
        }
        if (e.panel == s.cells && colBinding === 'amount_activity') {
          const rowData = s.rows[e.row].dataItem
          if(rowData) {
            const idServiceSource = rowData?.evidences
            if(idServiceSource) {
              wjcCore.addClass(e.cell, "auto-increment");
            }
          }
        }
        const buttonBinds = ['view_detail', 'service_detail'];
        const buttonView = ['attach_file'];
        const isButtonDisabled = this.isReadOnlyDataTable && e.panel === s.cells && buttonBinds.includes(colBinding);
        const isButtonViewDisabled = this.isReadOnlyDataTable && e.panel === s.cells && buttonView.includes(colBinding);
        if (isButtonDisabled) {
          wjcCore.addClass(e.cell, "btn-disabled");
        }
        if(isButtonViewDisabled) {
          const hasFileAttached = e.cell.querySelector('.file-attached-container') !== null;
          if (!hasFileAttached) {
           return  wjcCore.addClass(e.cell, "btn-disabled");
        } else {
          this.onlyViewEvidence = true;
           return
        }
        }
      });
      grid.cellEditEnded.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range
        const cellData = s.getCellData(row, col, false)
        const view = s.collectionView
        const source = view.sourceCollection
        const currentItem = source[row] || {}
        const binding = column.binding
        const dataStore = getDbCustomizeOrDbMasterById(this.dbMasterOriginal);
        const item = s.rows[e.row].dataItem;

        if(['amount_activity'].includes(column.binding)){
          const isNumber = $_helper_isNumberType(cellData);
          const range = 25;
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          const valueFormat = addThoundSandComma(formatValue(cellData)?.substring(0, subStringRang))
          s.setCellData(row, col, isNumber ? valueFormat : '', false, true);
        }

        const dataBinding = { s, binding, row, cellData, currentItem, dataStore }
        this.autoBindingDbSource(dataBinding);
        this.updateTotalEmissionsHandler();
        this.checkTableHasData();
      });
      grid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        
        if (
          column.binding === 'wsu_unit' ||
          column.binding === 'wsu_value' ||
          column.binding === 'wsu_source' ||
          column.binding === 'emissions'
        ) {
          e.cancel = true;
        }
      });
      grid.pasted.addHandler((s, e) => {
        const { col, col2, row, row2 } = e.range
        const view = s.collectionView
        const source = view.sourceCollection
        const dataStore = getDbCustomizeOrDbMasterById(this.dbMasterOriginal);
        
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const currentItem = source[rowIndex] || {}
            const binding = s.columns[colIndex].binding
            const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore }
            if(['amount_activity'].includes(binding)){
              const isNumber = $_helper_isNumberType(cellData);
              const range = 25;
              const subStringRang = cellData?.includes('-') ? range + 1 : range;
              const valueFormat = addThoundSandComma(formatValue(cellData)?.substring(0, subStringRang))
              s.setCellData(rowIndex, colIndex, isNumber ? valueFormat : '', false, true);
            }
            this.autoBindingDbSource(dataBinding);
            this.updateTotalEmissionsHandler();
          }
        }
        this.checkTableHasData();
        setTimeout(() => {
          this.actionUpdateIsLoadingTable(false)
        }, 0)
      });
      grid.pastingCell.addHandler((s, e) => {
        if ('energy_type' === grid.columns[e.col].binding) {
          this.handlePastingSource(e.data);
        }
        if (['wsu_value', 'wsu_unit', 'wsu_source', 'emissions'].includes(grid.columns[e.col].binding)) {
          e.cancel = true;
          return;
        }
      })
      grid.deletedRow.addHandler(() => {
        this.checkTableHasData();
        this.updateTotalEmissionsHandler();
      })
      this.highlightChangedRows();
    },
    handlePastingSource(source) {
      this.lostFocusId = null;
      let item = source;
      const dbItemData = dbIdeaBySourceName[item] || dbServiceLinkBySourceName[item];
      let index = this.dbMasterOriginal.findIndex(db => db.source === item);
      
      if (item && index < 0 && dbItemData) {
        this.dbMasterOriginal.push({...dbItemData, item_name: dbItemData.source });
                
        this.flexGrid.columnGroups = this.getHeaderTable;
        const newId = makeNewId(dbItemData?.id, dbItemData?.type);
        this.lostFocusId = newId;
      }
    },
    handleUpdateAutoFill(rowData) {
      const wsuSource = rowData.energy_type;
      const databaseTypeObject = getDbCustomizeOrDbMasterById(this.dbMasterOriginal)[wsuSource];
      rowData.wsu_source = databaseTypeObject?.source;
      rowData.wsu_value = databaseTypeObject?.value_source;
      rowData.wsu_unit = databaseTypeObject?.unit_source;
      const emissions = this.calculatorEmissions(rowData.wsu_value, rowData.amount_activity);
      const emission25Number = formatValue(emissions)?.includes('-') ? 26 : 25;
      const newEmissions = addThoundSandComma(formatValue(emissions)?.substring(0, emission25Number));
      rowData.emissions = newEmissions;
    },
    actionRightClick(action) {
      if (action === ACTION_CODE.DELETE) {
        this.checkTableHasData();
        // this.updateTotalEmissionsHandler();
      }
    },
    highlightChangedRows() {
      if (this.emissionsProductData !== undefined && this.flexGrid) {
        const autoFields = ['wsu_unit', 'wsu_source', 'wsu_value']
        this.flexGrid.formatItem.addHandler((handler, eventHandler) => {
          let accessRowIndex = eventHandler.row;
          let currentItem = this.emissionsProductData[accessRowIndex];
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged =
              currentItem !== undefined && currentItem['changed_columns'] !== undefined
                ? currentItem['changed_columns']
                : [];
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let column = handler.columns[eventHandler.col];

              if (autoFields.includes(column.binding) && currentItemChanged[column.binding]) {
                delete currentItemChanged[column.binding];
              }

              if ("name" in currentItemChanged) {
                currentItemChanged.item_name = currentItemChanged.name
              }
              if ("type" in currentItemChanged) {
                currentItemChanged.energy_type = currentItemChanged.type
              }
              if ("activity_level" in currentItemChanged) {
                currentItemChanged.amount_activity = currentItemChanged.activity_level
              }

              if ("activity_level_unit" in currentItemChanged) {
                currentItemChanged.amount_activity_unit = currentItemChanged.activity_level_unit
              }
              
              if ("db_master_id" in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_master_id"];
                });
              }
              if ("db_customize_id" in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_customize_id"];
                });
              }
              if ("db_relation" in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged["db_relation"];
                });
              }
              if ("evidence" in currentItemChanged) {
                currentItemChanged.attach_file = currentItemChanged.evidence
              }

              if (
                Object.keys(currentItemChanged).length &&
                (Object.keys(currentItemChanged).includes(column.binding))
              ) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                let updatedAt = moment
                  .utc(currentItemChanged[column.binding].updated_at)
                  .add(9, 'hours')
                  .format('YYYY/MM/DD HH:mm:ss');
                let updatedBy = currentItemChanged[column.binding].updated_by;
                if (this.isCheckLang === 'vi') {
                  tooltip.setTooltip(
                    eventHandler.cell,
                    `<div class='wj-cell-changed'>${this.$t("register_data.description_modified_by")} ` +
                    ` ${this.$t("register_data.description_administrator")}<br/>` + updatedBy + ' ' +
                    updatedAt +
                    '</div>',
                  );
                } else {
                  tooltip.setTooltip(
                    eventHandler.cell,
                    `<div class='wj-cell-changed'>${this.$t("register_data.description_administrator")} ` +
                    updatedBy +
                    ` ${this.$t("register_data.description_modified_by")}<br/>` +
                    updatedAt +
                    '</div>',
                  );
                }
              }
            }
          }
        });
      }
    },
    handleContextPasted() {
      this.checkTableHasData();
      // this.updateTotalEmissionsHandler();
    },
    updateTotalEmissionsHandler() {
      let sourceCollectionCurrent = this.emissionsProductGrid?.sourceCollection;
      this.totalEmissions = 0;
      sourceCollectionCurrent?.forEach((item) => {
       if ((item?.id === 'Blank') && !item['emissions']) return true;
        const newEmissionCalc = this.calculatorEmissions(item.wsu_value, item.amount_activity)
        if (!newEmissionCalc) return
        this.totalEmissions = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalEmissions)).toString()} + ${math.bignumber(formatValue(newEmissionCalc)?.substring(0, 50)).toString()}`), 50);
      });
      this.handleCalcEmissionsPerProduct();
    },

    formatNumber(num) {
      if (num === 0) {
        return '---'
      }

      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },

    autoBindingDbSource(dataBinding) {
      const { s, binding, row, cellData, currentItem, dataStore } = dataBinding
      if (binding === 'amount_activity') {
        s.deferUpdate(() => {
          const emissions = this.calculatorEmissions(currentItem.wsu_value, currentItem.amount_activity);
          const emission25Number = formatValue(emissions)?.includes('-') ? 26 : 25;
          const newEmissions = emissions === null ? null : addThoundSandComma(formatValue(emissions)?.substring(0, emission25Number));
          s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), newEmissions, false, true)
        })
      }

      if (binding === 'energy_type') {
        let dbItem = dataStore[cellData]
        if (this.lostFocusId && !dbItem) {
          dbItem = dataStore[this.lostFocusId]
        }
        s.deferUpdate(() => {
          const { SERVICE_LINK, IDEA } = DB_TYPE;
          currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id
          if (dbItem) {
            if (dbItem?.type === SERVICE_LINK) {
              s.rows[row].dataItem.detailExternalProductCode = currentItem.idDbExternal
              s.setCellData(row, getColumnIndexByBinding(s, 'amount_activity'), dbItem.numberValue, false, true)
            } else {
              s.rows[row].dataItem.detailExternalProductCode = null
            }
            s.setCellData(row, getColumnIndexByBinding(s, 'energy_type'), dbItem.id, false, true)
            // s.setCellData(row, getColumnIndexByBinding(s, 'unit'), dbItem.unit, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_value'), dbItem.value_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_unit'), dbItem.unit_source, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_source'), dbItem.source, false, true)

            const emissions = this.calculatorEmissions(dbItem.value_source, currentItem.amount_activity)
            const emission25Number = formatValue(emissions)?.includes('-') ? 26 : 25;
            const newEmissions = addThoundSandComma(formatValue(emissions)?.substring(0, emission25Number));
            s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), newEmissions, false, true)
          } else {
            s.setCellData(row, getColumnIndexByBinding(s, 'energy_type'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_value'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_unit'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'wsu_source'), null, false, true)
            s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), this.calculatorEmissions(currentItem.wsu_value, currentItem.amount_activity), false, true)
          }
        })

      }
    },

    calculatorEmissions(valueSource, value) {
      if ($_helper_isNumberType(valueSource) && $_helper_isNumberType(value)) {
        const valSource = math.bignumber(formatValue(valueSource));
        const valSale = math.bignumber(formatValue(value))
        return formatBigNumber(math.evaluate(`${valSource.toString()} * ${valSale.toString()}`), 50)
      }
      if ((valueSource === null || valueSource === '' || valueSource === undefined) && (value === null || value === '' || value === undefined)) {
        return null
      }
      return 0
    },
    handleCalcEmissionsPerProduct() {
      this.emissionsPerProduct = this.processDetailInfo.productNumber && this.totalEmissions !== 0 ? addThoundSandCommaWithTwoDigit(formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.totalEmissions)).toString()} / ${math.bignumber(formatValue(this.processDetailInfo.productNumber)).toString()} `), 35).replace(/[^0-9\.\-]+/g, ''), '', false, true) : 0;
    },

    initGridColumn() {
      return [
        {
          header: '連番',
          binding: 'id',
          minWidth: 40,
          maxWidth: 65,
          allowSorting: false,
          isReadOnly: true,
          visible: false,
          isRequired: false,
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_item_name'),
          binding: 'item_name',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_item_name')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left',
          isRequired: false,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_energy_type'),
          binding: 'energy_type',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_energy_type')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left',
          dataMap: new wjGrid.DataMap(prepareMasterOrCustomizeDB(this.dbMasterOriginal), 'id', 'item_name'),
          editor: new AutoComplete(document.createElement('div'), {
            itemsSource: prepareMasterOrCustomizeDB(this.dbMasterOriginal),
            selectedValuePath: 'item_name',
            displayMemberPath: 'item_name',
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1,
            lostFocus: (sender) => {
              this.handlePastingSource(sender.text);
            }
          }),
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_amount_activity'),
          binding: 'amount_activity',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_amount_activity'), 5),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'right'
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_amount_activity_unit'),
          binding: 'amount_activity_unit',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_amount_activity_unit')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left'
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_emissions_intensity'),
          align: 'center',
          columns: [
            {
              header: this.$t('cfp_emissions_product.table_emissions_product_number'),
              binding: 'wsu_value',
              minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_number')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              cssClass: 'auto-increment',
              wordWrap: true,
              align: 'right',
            },
            {
              header: this.$t('cfp_emissions_product.table_emissions_product_unit'),
              binding: 'wsu_unit',
              minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_unit')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              cssClass: 'auto-increment',
              wordWrap: true,
            },
            {
              header: this.$t('cfp_emissions_product.table_emissions_product_source'),
              binding: 'wsu_source',
              minWidth: 349,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              cssClass: 'auto-increment',
              wordWrap: true,
            }
          ],
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_emission'),
          binding: 'emissions',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_emission')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'right'
        },
        {
          header: this.$t('56_pattern.table_external_basic_unit'),
          binding: "view_detail",
          // maxWidth: getWidthOfSearchButton(this.$i18n.locale),
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClassAll: "search-detail hide-filter",
          cellTemplate: CellMaker.makeButton({
            text:
              `<div class="search-container">
              ${i18n.t('register_data.button_search')}
              <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
            </div>`,
            click: (e, ctx) => this.externalSourcePopup(ctx, 'wsu_source')
          }),
        },
        {
        header      : this.$t('56_pattern.table_collaborative_service'),
        binding     : "service_detail",
        maxWidth: getWidthOfSmartLcaBtn(this.$i18n.locale),
        allowSorting: false,
        wordWrap    : true,
        isRequired: false,
        cssClassAll : "search-detail hide-filter",
        cellTemplate:  (ctx, el) => {
          const detailExternalProductCode = ctx.item?.detailExternalProductCode
          const text = detailExternalProductCode ? this.$t('b_list_submited.button_detail') : this.$t('register_data.button_search');
          const button = CellMaker.makeButton({
            text : `<div class="search-container">
              ${text}
              <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
            </div>`,
            click: (e, ctx) => this.serviceLinkPopup(ctx)
            })
            return button(ctx, el);
          } 
        },
        {
          header      : this.$t('56_pattern.table_attach_file'),
          binding     : "attach_file",
          cssClass: 'auto-increment',
          minWidth    : getWidthOfAttachedButton(this.$i18n.locale),
          maxWidth    : getWidthOfAttachedButton(this.$i18n.locale),
          allowSorting: false,
          isRequired  : false,
          cssClassAll: "btn-db attach-file hide-filter",
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.table_attach_file');
            if (ctx.item?.evidence) {
              text = `<div class="file-attached-container">
                <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
              </div>`
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.attachFilePopup(e, context)
            })
            return button(ctx, el);
        } 
      },
      ];
    },
    handleCheckNote(value) {
      if (value.length > 255) {
        this.productEmissionsErrorMessages.noteErrorMessages.push(this.$t('validation.error_maximum_by_charactors', { charactors: '255' }));
      }
    },
    attachFilePopup(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      this.descriptionScope.detailExternalProductCode = context.item?.detailExternalProductCode
      let evidenceData = context?.item?.evidence;
      if (typeof evidenceData === 'string') {
        evidenceData = JSON.parse(evidenceData)
      }
      this.onlyViewEvidence = false;

      if(evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      }
      else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.evidences.value = this.emissionsProductGrid.sourceCollection[this.selectedRow]
        ? this.emissionsProductGrid.sourceCollection[this.selectedRow]['amount_activity']
        : null;
      this.openDialogEvidenceStorage = true;
    },
    updateEmissionsForRow(rowIndex) {
      const row = this.emissionsProductGrid.sourceCollection[rowIndex];
      const emissions = this.calculatorEmissions(row.wsu_value, row.amount_activity);
      const emission25Number = formatValue(emissions)?.includes('-') ? 26 : 25;
      const newEmissions = addThoundSandComma(formatValue(emissions)?.substring(0, emission25Number));
      
      row.emissions = newEmissions;
    },
    handleAddAttachFile(dataEvidence) {
      let evidenceDataInCellTable = this.emissionsProductGrid.items[this.selectedRow]?.evidence;
      this.emissionsProductGrid.editItem(this.emissionsProductGrid.items[this.selectedRow]);
      dataEvidence = {
        ...evidenceDataInCellTable,
        ...dataEvidence,
        created_at: evidenceDataInCellTable ? evidenceDataInCellTable.created_at : dataEvidence.created_at // TODO: always get the first created date of the record
      }
      const numericValue = dataEvidence?.value;
      const rowIndex = this.selectedRow;
      this.updateFlexGridCells(rowIndex, {
        amount_activity: numericValue,
        attach_file: null
      });
      this.updateFlexGridCells(rowIndex, {
        amount_activity: numericValue,
        attach_file: dataEvidence?.file_name
      });
      if(this.emissionsProductGrid.items[rowIndex] && dataEvidence?.file_name) {
        this.emissionsProductGrid.items[rowIndex].evidence = dataEvidence
        this.emissionsProductGrid.items[rowIndex].attach_file = dataEvidence.file_name;
      }
      if(!isNaN(parseInt(numericValue))) {
        this.emissionsProductGrid.items[rowIndex]['amount_activity'] = addThoundSandComma(formatValue(numericValue));
      }
      else {
        this.emissionsProductGrid.items[rowIndex]['amount_activity'] = '';
      }
      this.updateEmissionsForRow(rowIndex);
      this.updateTotalEmissionsHandler()
      this.checkTableHasData();

      const isHasChangeEvidence = this.checkEvidenceChangeData(dataEvidence, evidenceDataInCellTable);
      if (isHasChangeEvidence) {
        const itemChange = this.emissionsProductGrid.items[rowIndex]
        if(itemChange.id) {
          this.itemsChangeEvidence[itemChange.id] = { ...itemChange, rowIndex: rowIndex}
        }
        setTimeout(() => {
          // this.isEditDataTable = true;
          this.isChangeEvidence = true;
        }, 100)
      }
      this.emissionsProductGrid.commitEdit();

    },
    checkEvidenceChangeData(newEvidence, oldEvidence) {
      return !Object.keys(newEvidence).every(key => newEvidence?.key && oldEvidence?.key && newEvidence[key] === oldEvidence[key]);
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      this.updateFlexGridCells(this.selectedRow, {
        attach_file: null,
      });
      this.emissionsProductGrid.sourceCollection[this.selectedRow].evidence = null;
      this.emissionsProductGrid.sourceCollection[this.selectedRow].attach_file = null;
      this.checkTableHasData();
    },
    serviceLinkPopup(context) {
      this.selectedRow = context.row.index;
      const rowData = context?.row?.dataItem;
      const id = rowData?.wsu_source; // force to s3c1
      const dbService = dbServiceLinkObj[id]
      this.codeProductValue = dbService?.product_code || rowData?.detailExternalProductCode;
      this.onlyViewedServicesLinked = rowData?.detailExternalProductCode ? true : false;

      if (this.isViewOnlyServiceLink) {
        if(this.codeProductValue) {
          this.emissionsProductGrid.sourceCollection[this.selectedRow].detailExternalProductCode = this.codeProductValue;
          this.serviceLinkDialog = true;
        }
        return;
      }
      this.serviceLinkDialog = true;
    },
    addServiceLinkSource(data, rowIndex, detailExternalProductCode, typeSource) {
      const dataAutoBinding = [];
      data.item.packages.forEach((item, i) => {
        if (item.package_value) {
          const newId = makeNewId(item.product_package_code, typeSource)
          const isIdDuplicate = this.dbMasterOriginal.some(item => {
            return item.id_minor_processed === newId
          });
          const nameSourceCustome = item.source;
          if (!isIdDuplicate) {
            const sourceTemplate = {
              ...item,
              id: item.product_package_code,
              item_name: nameSourceCustome,
              type: typeSource,
              source: nameSourceCustome,
              id_minor_processed: newId,
              product_code: data.item?.code,
              numberValue: item.package_value,
            }
            this.addedDbExternalToMasterDB(sourceTemplate)
          }
          dataAutoBinding.push({
            energy_type: newId,
            wsu_unit: item.unit_source,
            wsu_source: nameSourceCustome,
            wsu_value: item.value_source,
            product_package_code: item.product_package_code
          })
        }
      })
      this.flexGrid.columnGroups = this.getHeaderTable;
      this.flexGrid.columns.forEach(column => {
        const columnData = this.flexGrid.getColumn(column);
        if (columnData) {
          columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
        }
      });
      dataAutoBinding.forEach((item, index) => {
        const row = rowIndex + index;
        const newId  = makeNewId(item.product_package_code, typeSource)
        this.updateFlexGridCells(row, item);
        // this.updateFlexUnitCells(row, item);
        if(this.emissionsProductGrid.sourceCollection[row]) {
          this.emissionsProductGrid.sourceCollection[row].idDbExternal = newId
          const dbRelationKey = getDbRelationKeysByType(typeSource);
          this.emissionsProductGrid.sourceCollection[row].db_relation = {
            [dbRelationKey]: item.product_package_code,
          }
          this.emissionsProductGrid.sourceCollection[row].detailExternalProductCode = detailExternalProductCode || data.item?.code;
        }
        this.updateEmissionsForRow(row);
      })
      this.onlyViewedServicesLinked = true;
      this.updateTotalEmissionsHandler()
      this.checkTableHasData();
    },
    updateFlexGridCells(rowIndex, cellData) {
      if (!this.emissionsProductGrid.sourceCollection[rowIndex]) {
        this.addBlankItemsToView(1);
      }
      for (const columnName in cellData) {
        const colIndex = getColumnIndexByBinding(this.flexGrid, columnName)
        if (colIndex >= 0) {
          this.flexGrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    removeHeaderItem(header, bindingName) {
      const indexRemoved = header.findIndex(h => h.binding === bindingName);
      if (indexRemoved !== -1) {
        header.splice(indexRemoved, 1);
      }

      if(indexRemoved === -1) {
        header.forEach(headerItem => {
          if(headerItem.columns?.length > 0) {
            const subIndexRemoved = headerItem.columns.findIndex(sub => sub.binding === bindingName);
            if (subIndexRemoved !== -1) {
              headerItem.columns.splice(subIndexRemoved, 1);
            }
          }
        })
      }
    },
    // TODO: 20899
    async handleGetServicesLinked() {
      try {
        const dataResponse = await getServicesLinked(true);
        // this.listServicesLinked = dataResponse.data;
        this.listServicesLinked = [];
        this.flexGrid.columnGroups = this.getHeaderTable;
      } catch (error) {
        console.warn(error);
      }
    },
    initialView() {
      if (this.emissionsProductGrid) {
        this.emissionsProductData = this.emissionsProductGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.emissionsProductGrid = new CollectionView([...this.emissionsProductData], {
        trackChanges: true,
      });
      this.addBlankItemsToView(10);
      setMinMaxSizeColumns(this.flexGrid, this.emissionsProductData);
      const itemsInView = ['item_name', 'amount_activity', 'amount_activity_unit', 'energy_type', 'emissions', 'wsu_unit', 'wsu_value', 'wsu_source'];
      this.emissionsProductGrid?.collectionChanged.addHandler(throttle(() => {
        this.emissionsProductGrid.itemsAdded.forEach((itemAdded) => {
          if (itemsInView.every((field) => itemAdded[field] === null || itemAdded[field] === '' || itemAdded[field] === undefined)) {
            return null;
          }
          const dataObject = this.dataStoreData[itemAdded.energy_type];
          itemAdded.wsu_value = dataObject?.value_source || null
          itemAdded.wsu_unit = dataObject?.unit_source || null
          itemAdded.wsu_source = dataObject?.source || null

          const emissions = this.calculatorEmissions(itemAdded.wsu_value, itemAdded.amount_activity)
          const emission25Number = formatValue(emissions)?.includes('-') ? 26 : 25;
          const newEmissions = addThoundSandComma(formatValue(emissions)?.substring(0, emission25Number));
          itemAdded.emissions = newEmissions;
        })

        this.updateTotalEmissionsHandler();
      }));

      this.listOriginalData = JSON.parse(JSON.stringify(this.emissionsProductGrid.items));

      this.flexGrid.columnGroups = this.getHeaderTable;
      this.emissionsProductGrid.currentItem = null;
    },
    addBlankItemsToView(count) {
      const lastClientId = this.emissionsProductGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.emissionsProductGrid.addNew(this.blankData(lastClientId + index));
      }

      this.emissionsProductGrid.commitNew();
      // this.emissionsProductGrid.clearChanges();
    },
    blankData(clientRowId) {
      return {
        clientRowId: clientRowId,
        id: BLANK_ID,
        item_name: null,
        energy_type: null,
        amount_activity: null,
        amount_activity_unit: null,
        wsu_value: null,
        wsu_unit: null,
        wsu_source: null,
        emissions: null
      };
    },
    handleUpdateProductNumber(newVal) {
      this.processDetailInfo.productNumber = newVal;
      this.handleCalcEmissionsPerProduct()
      this.checkTableHasData();
    },
    handleUpdateNoteField(newValue) {
      this.processDetailInfo.note = newValue;
      this.checkTableHasData();
    },
    defineTable() {
      this.emissionsProductColumns = this.getHeaderTable;
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    async getEmissionProductByProcess() {
      await actionProcessEmissionProduct('get', this.$route?.params?.processId).then((res) => {
        this.emissionsProductData = res.data.process_emissions.map(item => {
          const subStringRang = item?.activity_level?.toString()?.includes('-') ? 26 : 25;
          const { db_customize, db_source_detail, db_master, type, service_product_package_link } = item;

          const getEnergyType = (sourceArray) => Object.keys(getDbCustomizeOrDbMasterById(sourceArray))[0];
          const createSourceTemplate = (dbSource, dbType) => {
            const baseTemplate = {
              ...dbSource,
              source: dbSource.source,
              unit_source: dbSource.unit_source,
              value_source: dbSource.value_source,
              type: dbType,
              item_name: dbSource.source,
              unit: dbSource.unit
            };
            if (dbType === DB_TYPE.SERVICE_LINK) {
              baseTemplate.id = dbSource.product_package_code;
            }
            return baseTemplate;
          };

          let dbSource = '';
          let energyType = '';
          let sourceTemplate;

          switch (type) {
            case DB_TYPE.CUSTOMIZE:
              dbSource = db_customize;
              energyType = getEnergyType([db_customize]);
              break;
            case DB_TYPE.IDEA:
              dbSource = db_source_detail[0];
              energyType = getEnergyType(db_source_detail.map(detail => ({ ...detail, type: item.type })));
              sourceTemplate = createSourceTemplate(dbSource, DB_TYPE.IDEA);
              this.addedDbExternalToMasterDB(sourceTemplate);
              break;
            case DB_TYPE.SERVICE_LINK:
              dbSource = service_product_package_link[0];
              energyType = getEnergyType(service_product_package_link.map(detail => ({ ...detail, type: item.type, id: detail.product_package_code })));
              sourceTemplate = createSourceTemplate(dbSource, DB_TYPE.SERVICE_LINK);
              this.addedDbExternalToMasterDB(sourceTemplate);
              break;
            case DB_TYPE.MASTER:
              dbSource = db_master;
              db_master.type = 1;
              energyType = getEnergyType([db_master]);
              break;
          }

          const wsuValue = dbSource.value_source || null;
          const wsuUnit = dbSource.unit_source || null;
          const wsuSource = dbSource.source || null;
          const evidence = typeof item?.evidence === 'string' ? JSON.parse(item?.evidence) : item?.evidence;

          return {
            ...item,
            item_name: item.name,
            amount_activity: addThoundSandComma(formatValue(item.activity_level)?.substring(0, subStringRang)),
            amount_activity_unit: item.activity_level_unit,
            energy_type: energyType,
            wsu_value: wsuValue?.toString(),
            wsu_unit: wsuUnit,
            wsu_source: wsuSource,
            attach_file: evidence?.file_name,
            evidence: evidence,
            emissions: addThoundSandComma(formatValue(item.emissions))
          };
        });

        this.processDetailInfo = res.data.process_detail;
        this.processDetailInfo.productNumber = addThoundSandComma(this.processDetailInfo.quantity?.toString()) || null;
        this.processDetailInfo.note = this.processDetailInfo?.note || '';
        this.processDetailInfo.selectedOption = this.processDetailInfo?.calculation_object || false;
        this.processDetailInfo.productUnit = this.processDetailInfo?.product_unit;
        this.processDetailInfo.noteOriginal = this.processDetailInfo?.note || '';
        this.processDetailInfo.methodOriginal = this.processDetailInfo.calculation_object;
        this.processDetailInfo.quantityOriginal = this.processDetailInfo?.quantity || null;
      })
      this.defineTable(this.emissionsProductData);
    },
    // TODO: Change get DB type
    async getListDb() {
      const masterData = await getDbProcessEmission({ contractor_id: this.$store.state.userData.contractor })
      this.dbMasterOriginal = masterData?.masterDb;
    },
    submitQuestionPopup() {
      this.questionPopupDialog = false;
      if (this.isUpdateDataApproved) {
        this.submitData();
      } else {
        this.$router.push({ name: "EmissionsDetailProduct", params: { emissionId: this.processDetailInfo?.product_emission_id }, query: this.$route.query }).catch(() => { });
      }
    },
    closeQuestionPopup() {
      this.isUpdateDataApproved = false;
      this.questionPopupDialog = false;
    },
    handleActionByName(nameAction) {
      if (nameAction === 'clickSaveEmissionsBtn') {
        this.clickSaveEmissionsBtn();
      } else {
        this.isUpdateDataApproved = false;
        this.questionPopupDialog = true;
        this.questionPopupMessage = this.$t("cfp_emissions_product.message_confirm_cancel_register_emissions")
        this.confirmText = this.$t('cfp_emissions_product.button_confirm_cancel_register_emissions_yes');
        this.closeText = this.$t('cfp_emissions_product.button_confirm_cancel_register_emissions_no');
      }
    },
    checkValidateAndShowPopupIfError() {
      let messageList = [];
      if (!this.processDetailInfo.productNumber) {
        messageList.push(this.$t("cfp_emissions_product.message_required_number_of_product"))
      }
      if (messageList.length > 0) {
        this.dialog.notification = true;
        this.message.notification = messageList.join('\n')
        return true;
      }
    },
    getError(item, propName) {
      const itemsInView = ['item_name', 'amount_activity', 'amount_activity_unit', 'energy_type', 'emissions', 'wsu_unit', 'wsu_value', 'wsu_source', 'attach_file'];
      if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
        return null;
      }
      switch (propName) {
        case "energy_type":
        case "wsu_unit":
        case "wsu_value":
        case "wsu_source":
        case "emissions":
        case "amount_activity_unit":
          return this.emptyCell(item[propName]);
        case "amount_activity":
          if (item[propName] === undefined || item[propName] === null || item[propName] === '') {
            return this.$t('validation.error_required_field');
          } else {
            return validateNumberField(String(item[propName]), propName);
          }
        case "item_name":
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 255);
      }
      return null;
    },
    
    emptyCell(item) {
      if (item === undefined || item === null || item === '') {
        return this.$t('validation.error_required_field');
      }
      return null;
    },

    externalSourcePopup(context) {
      if (!this.existDbSource) {
        return;
      }
      const rowData = context?.row?.dataItem;
      this.selectedRow = context.row.index;
      this.detailExternalSource = dbIdeaObj[rowData?.idDbExternal]?.db_source_id || rowData?.detailExternalSourceId;
      this.externalSourceDialog = true;
    },
    addExternalSource(data, rowIndex, detailExternalSourceId, typeSource) {
      const dataAutoBinding = [];
      const values = data.item;
      const nameSource = values.source;
      const valueSource = values.value_source;
      const unitSource = values.unit_source;
      const unit = values.unit;

      const newId = makeNewId(values.id, typeSource)
      const isIdDuplicate = this.dbMasterOriginal.some(item => {
        return item.id_minor_processed === newId
      });
      if (!isIdDuplicate) {
        const sourceTemplate = {
          ...values,
          id: values.id,
          source: nameSource,
          unit_source: unitSource,
          value_source: valueSource,
          id_minor_processed: newId,
          type: typeSource,
          item_name: nameSource,
          unit: unit
        }
        this.addedDbExternalToMasterDB(sourceTemplate)
      }
      
      const dataUpdateCell = {
        energy_type: newId,
        wsu_unit: unitSource,
        wsu_value: valueSource,
        wsu_source: nameSource,
        unit: unit,
      }
      this.flexGrid.columnGroups = this.getHeaderTable;
      this.flexGrid.columns.forEach(column => {
        const columnData = this.flexGrid.getColumn(column);
        if (columnData) {
          columnData.minWidth = getWidthByTextContent(columnData.header) + 10;
        }
      });
      dataAutoBinding.push(dataUpdateCell)
      dataAutoBinding.forEach((item, index) => {
        const row = rowIndex + index;
        const newId = makeNewId(values, typeSource)
        this.updateFlexgridCells(row, item);
        if (this.emissionsProductGrid.sourceCollection[row]) {
          this.emissionsProductGrid.sourceCollection[row].idDbExternal = newId
          const dbRelationKey = getDbRelationKeysByType(typeSource);
          this.emissionsProductGrid.sourceCollection[row].db_relation = {
            [dbRelationKey]: values,
          }
          this.emissionsProductGrid.sourceCollection[row].detailExternalSourceId = detailExternalSourceId;
        }
        this.updateEmissionsForRow(row);
      })
      this.updateTotalEmissionsHandler();
      this.checkTableHasData();
    },

    updateFlexgridCells(rowIndex, cellData) {
      if (!this.emissionsProductGrid.sourceCollection[rowIndex]) {
        this.addBlankItemsToView(this.emissionsProductGrid, 1);
      }
      for (const columnName in cellData) {
        const colIndex = this.getColumnIndexByBinding(columnName)
        if (colIndex >= 0) {
          this.flexGrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    getColumnIndexByBinding(binding) {
      return this.flexGrid.columns.findIndex((column) => column.binding === binding);
    },
    addedDbExternalToMasterDB(sourceTemplate) {
      const typeSource = sourceTemplate.type;
      if (!this.dbExternalAdded[typeSource].find(db => db.id === sourceTemplate.id)) {
        this.dbExternalAdded[typeSource].push(sourceTemplate);
        if (typeSource === DB_TYPE.SERVICE_LINK) {
          this.dbMasterOriginal.push(sourceTemplate)
        }
        else if (typeSource === DB_TYPE.IDEA) { // [master database, IDEA source ,[new item], service linked]
          this.dbMasterOriginal.splice(this.dbMasterOriginal.length - this.dbExternalAdded[DB_TYPE.SERVICE_LINK].length, 0, sourceTemplate)
        }
      }
    },
    clickSaveEmissionsBtn() {
      const isErr = this.checkValidateAndShowPopupIfError();
      this.emissionsProductGrid.getError = this.getError;
      if(isErr) {
        return;
      }
      
      this.submitData();
    },

    formatValueNumber(input) {
      return input ? input.toString().replace(/,/g, '') : '';
    },
    isValidFields(data) {
      return data.every(item => {
        const hasType = item.type !== '' && item.type !== null && item.type !== undefined;
        const hasActivityLevel = item.activity_level !== '' && item.activity_level !== null && item.activity_level !== undefined;
        const hasActivityLevelUnit = item.activity_level_unit !== '' && item.activity_level_unit !== null && item.activity_level_unit !== undefined;
        // case delete on cell: !hasType && !hasActivityLevel && !hasActivityLevelUnit && name === '' and does not exist evidence
        return (hasType && hasActivityLevel && hasActivityLevelUnit) || (!hasType && !hasActivityLevel && !hasActivityLevelUnit && (item.name === '') && !item.evidence);
      });
    },
    submitData() {
      let payload = {
        process_detail: {
          boundary_id: this.processDetailInfo.boundary_id,
          product_id: this.processDetailInfo.product_id,
          product_emission_id: this.processDetailInfo.product_emission_id,
          process_id: this.processDetailInfo.process_id,
          calculation_object: this.processDetailInfo.selectedOption,
          note: this.processDetailInfo.note,
          quantity: this.formatValueNumber(this.processDetailInfo.productNumber)
        }
      };

      const dataEditedOnTable = this.getDataChangeOnTable();

      if (!this.isValidFields(dataEditedOnTable)) return;
      if (this.productEmissionsErrorMessages.noteErrorMessages.length > 0) return;

      // Handle show popup confirm change data approved
      if (this.statusApproved && !this.isUpdateDataApproved) {
        this.isUpdateDataApproved = true;
        this.questionPopupDialog = true;
        this.questionPopupMessage = this.$t("cfp_emissions_product.message_confirm_updated_data_approved");
        this.confirmText = this.$t('register_data.button_keep');
        this.closeText = this.$t('popup.button_close');
        return;
      }
      // Prepare data call API
      const itemsRequired = ['type', 'name', 'emissions', 'activity_level', 'activity_level_unit'];
      if (this.processDetailInfo?.id) {
        const add = [];
        const edit = [];
        const dataDeleted = [];

        dataEditedOnTable.forEach(item => {
          const isAllFieldsEmpty = itemsRequired.every(
            field => item[field] === null || item[field] === '' || item[field] === undefined
          );

          if (Number(item.id)) {
            isAllFieldsEmpty ? dataDeleted.push(item.id) : edit.push(item);
          } else if (!isAllFieldsEmpty) {
            add.push({ ...item, id: undefined });
          }
        });
        this.emissionsProductGrid.itemsRemoved.forEach((item) => {
          if (item.id !== BLANK_ID) {
            dataDeleted.push(item.id);
          }
        })
        payload.process_detail.id = this.processDetailInfo.id;
        payload.data = { add, update: edit, delete: dataDeleted };
        this.saveEmissionProduct('put', payload);

      } else {
        const dataSubmit = dataEditedOnTable
          .filter(item => !itemsRequired.every(
            field => item[field] === null || item[field] === '' || item[field] === undefined
          ))
          .map(({ id, ...rest }) => rest);

        payload.data = dataSubmit;
        this.saveEmissionProduct('post', payload);
      }


    },
    async saveEmissionProduct(method, payload) {
      try {
        const res = await actionProcessEmissionProduct(method, payload, this.processDetailInfo.process_id);
        this.dialog.notificationSaved = true;
        this.message.savedChange = this.$t('cfp_emissions_product.message_saved_changes');
      } catch (error) {
        this.dialog.notification = true;
        this.message.notification = Object.values(error?.errors).join('\n');
      }
    },

    getDataChangeOnTable() {
      let dataRegisterOnTable = [];

      const source = getDbCustomizeOrDbMasterById(this.dbMasterOriginal);
      const addItemToTable = (emissionsProcessItem) => {
        const index = this.emissionsProductGrid.items.indexOf(emissionsProcessItem) + 1;
        const wsuSource = emissionsProcessItem.energy_type;
        const databaseTypeObject = source[wsuSource];
        if (emissionsProcessItem?.evidence) {
          emissionsProcessItem.evidence.value = emissionsProcessItem?.amount_activity;
        }
        const dbRelationKey = getDbRelationKeysByType(databaseTypeObject?.type);

        dataRegisterOnTable.push({
          id: emissionsProcessItem.id,
          name: emissionsProcessItem?.item_name || '',
          activity_level: formatValue(emissionsProcessItem?.amount_activity),
          activity_level_unit: emissionsProcessItem?.amount_activity_unit,
          emissions: this.formatValueNumber(emissionsProcessItem.emissions),
          boundary_id: this.processDetailInfo.boundary_id,
          product_id: this.processDetailInfo.product_id,
          process_id: this.processDetailInfo.process_id,
          product_emission_id: this.processDetailInfo.product_emission_id,
          type: databaseTypeObject?.type !== undefined ? databaseTypeObject.type : null,
          db_relation: {
            [dbRelationKey]: databaseTypeObject?.origin_id
          },
          db_customize_id: databaseTypeObject?.type === DB_TYPE.CUSTOMIZE ? databaseTypeObject?.origin_id || null : null,
          db_master_id: databaseTypeObject?.type === DB_TYPE.MASTER ? databaseTypeObject?.origin_id || null : null,
          index: index,
          evidence: emissionsProcessItem.evidence || null,
        });
      };
     
      this.emissionsProductGrid.itemsEdited.forEach(addItemToTable)
      this.emissionsProductGrid.itemsAdded.forEach(addItemToTable)

      return dataRegisterOnTable
    },
  
    handleResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    updateActionPosition() {
      const scrollElementTop = this.$refs.scrollElement?.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > (scrollElementTop + 215)) { // scrollElementTop + 215 ===  the space between the buttons with top screen
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    
    hasDataChanged(original, current) {
      // current null return false
      if(Array.isArray(current)) {
        if (original.length !== current.length) {
          return true; // If lengths are different, data has changed
        }

        for (let i = 0; i < original.length; i++) {
          for (let key in original[i]) {
            if (original[i][key] !== current[i][key]) {
              return true; // Early exit on first detected change
            }
          }
        }
      }

      return false; // If nothing has changed, return false
    },
    checkTableHasData() {
      this.isHasDataTable = false;
      const hasDataInTable = this.hasDataChanged(this.listOriginalData, this.emissionsProductGrid?.sourceCollection);

      const hasDataInInputs = this.processDetailInfo.selectedOption !== this.processDetailInfo.methodOriginal ||
                              this.formatValueNumber(this.processDetailInfo.productNumber) !== this.formatValueNumber(this.processDetailInfo.quantityOriginal) ||
                              this.processDetailInfo.note !== this.processDetailInfo.noteOriginal;
      this.isHasDataTable = hasDataInTable || hasDataInInputs;
    },
    getDataPulldown() {
      this.checkTableHasData();
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.updateActionPosition);
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>
<style lang="scss" scoped>
.emission-product {
  &__body {
    &__info {
      &__unit-section {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        gap: 20px;
        border-radius: 4px 0px 0px 0px;
        .question {
          position: relative;
          height: 24px;
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .emission-quantity {
          label {
            display: flex;
            align-items: center;
          }
          ::v-deep .input-custom{
            &.input-service-link {
              &.color-textColor {
                 &.input-field {
                  flex-grow: unset;
                }
              }
            }
          } 
        }
        &__input-block {
          display: flex;
          flex-direction: column;
          gap: 6px;

          label {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-align: left;
            color: $monoBlack;
            .tooltip {
              margin-left: 8px;
            }
          }

          .text-field {
            border: 1px solid $monoBlackDark;
            gap: 0px;
            border-radius: 4px 0px 0px 0px;
            border: 1px 0px 0px 0px;
            padding: 7px 16px 9px 16px;
            gap: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-align: left;
            color: $monoBlack;
            word-wrap: break-word;
            overflow-wrap: break-word;
            &-unit {
              white-space: nowrap;
            }
          }
        }
      }

      &__note-section {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 16px 20px;
        ::v-deep .input-field {
          &.input-custom {
            height: unset;
            border: unset;
            background: transparent;
            box-shadow: unset;
            .v-input__slot {
              background: $monoWhite;
            }
          }
        } 
        label {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.03em;
          text-align: left;
          color: $monoBlack;
        }
      }
    }
    &__table {
      ::v-deep .search-detail {
        &.hide-filter {
          .wj-btn-glyph {
            display: none
          }
        }
      }
    }
  }
}

@include desktop {
  .emission-product {
    &__body {
      &__info {
        &__unit-section {
          display: flex;
          flex-direction: row;
          padding: 16px 40px 16px 40px;
          gap: 20px;
          border-radius: 4px 0px 0px 0px;

          &__input-block {
            display: flex;
            flex-direction: column;
            gap: 6px;
            flex: 1;
            width: calc(100%/3);
          }
        }

        &__note-section {
          padding: 16px 40px 24px 40px;
          gap: 8px;
          border-radius: 4px 0px 0px 0px;
        }
      }
    }
  }
}
</style>
